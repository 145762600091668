import LoremIpsum from 'react-lorem-ipsum';

const Home = () => (
  <div>
    <h2>Welcome to our page!</h2>
    <LoremIpsum p={2} />
  </div>
);

export default Home;
